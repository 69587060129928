<template>
    <div>
        <v-data-table :loading="loadingTable" :headers="headers" :items="guests" sort-by="calories" class="elevation-1"
            :page-count="numberOfPagesPaginate" :page="currentPagePaginate" :server-items-length="totalStagesPaginate"
            :options.sync="options" :footer-props="{
                itemsPerPageText: 'Itens por pagina',
                itemsPerPageOptions: [5, 10, 15],
                showFirstLastPage: true,
            }">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Tarifas</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark rounded class="mb-2" @click="showDialogNewGuest = true">
                        <v-icon small>
                            {{ icons.mdiPlus }}
                        </v-icon>
                        Nova Tarifa
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:item.value="{ item }">
                {{ formatMoney(item.value) }}
            </template>
            <template v-slot:item.period="{ item }">

                {{ formatPeriod(item) }}

            </template>
            <template v-slot:item.actions="{ item }">
                <div style=" white-space: nowrap;">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon color="primary" class="mr-2" @click="editItem(item)" v-bind="attrs" v-on="on">
                                {{ icons.mdiPencilOutline }}
                            </v-icon>

                        </template>
                        <span>Editar</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon color="error" @click="deleteItem(item)" v-bind="attrs" v-on="on">
                                {{ icons.mdiDeleteOutline }}
                            </v-icon>
                        </template>
                        <span>Excluir</span>
                    </v-tooltip>
                    <!-- <v-icon color="primary" class="mr-2" @click="editItem(item)">
                {{ icons.mdiPencilOutline }}
            </v-icon>
            <v-icon color="error" @click="deleteItem(item)">
                {{ icons.mdiDeleteOutline }}
            </v-icon> -->
                </div>
            </template>
            <template v-slot:no-data>
                <NoDataTable></NoDataTable>
            </template>
            <template v-slot:loading>
                Carregando...
            </template>
        </v-data-table>
        <DialogNewGuestVue v-model="showDialogNewGuest" :in_data='editedItem' @out_data='saveConfirm'
            :title="formTitle" />
        <MsgDelete v-model="dialogDelete" @delete-yes="deleteItemConfirm()" />
    </div>
</template>

<script>
import MsgDelete from '@/components/MsgDelete.vue'
import NoDataTable from '@/components/NoDataTable.vue'
import {
    mdiClose,
    mdiDeleteOutline,
    mdiPencilOutline,
    mdiPlus
} from '@mdi/js'
import {
    mapActions
} from 'vuex'
import DialogNewGuestVue from './_components/DialogTariff.vue'


export default {
    components: {
        MsgDelete,
        NoDataTable,
        DialogNewGuestVue,
    },
    data: () => ({
        showDialogNewGuest: false,
        loadingTable: false,
        totalStagesPaginate: 0,
        numberOfPagesPaginate: 0,
        currentPagePaginate: 0,
        dialogDelete: false,
        dialog: false,
        loading: false,
        headers: [{
            text: 'ID',
            align: 'start',
            value: 'id',
        },
        {
            text: 'DESCRIÇÃO',
            value: 'description'
        },
        {
            text: 'TARIFA',
            value: 'value'
        },
        {
            text: 'PERIODO',
            align: 'center',
            value: 'period'
        },
        {
            text: '',
            align: 'end',
            value: 'actions',
            sortable: false,
        },
        ],
        guests: [],
        editedIndex: -1,
        editedItem: {},
        options: {},
        tab: null,
        items: [{
            tab: 'One',
            content: 'Tab 1 Content'
        },
        {
            tab: 'Two',
            content: 'Tab 2 Content'
        },

        ],
        icons: {
            mdiPencilOutline,
            mdiDeleteOutline,
            mdiPlus,
            mdiClose,
        },
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nova Tarifa' : 'Editar Tarifa'
        },

    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
        options: {
            handler() {
                this.initialize()
            },
        },
    },

    created() {
        this.initialize()
    },

    methods: {
        ...mapActions('tariff', ['getAll', 'store', 'update', 'delete']),
        initialize() {
            this.loadingTable = true

            const {
                page,
                itemsPerPage
            } = this.options

            const payload = {
                itemsPerPage: itemsPerPage || 10,
                page,
                search: this.search,
            }

            this.getAll(payload)
                .then(response => {
                    this.guests = response.data.data
                    this.totalStagesPaginate = response.data.total
                    this.numberOfPagesPaginate = response.data.last_page
                    this.currentPagePaginate = response.data.current_page
                })
                .finally(() => {
                    this.loadingTable = false
                })
        },

        editItem(item) {
            this.editedIndex = this.guests.indexOf(item)
            this.editedItem = {
                ...item
            }
            this.showDialogNewGuest = true
        },

        saveConfirm(guest) {
            if (this.editedIndex == -1) {
                this.guests.push(guest) //adiciona no array
            } else {
                Object.assign(this.guests[this.editedIndex], guest) // atualiza o guest no array
            }
        },

        deleteItem(item) {
            this.dialogDelete = true
            this.editedItem = item
        },

        deleteItemConfirm() {
            const index = this.guests.indexOf(this.editedItem)
            this.delete(this.editedItem)
                .then(() => {
                    this.guests.splice(index, 1)
                    this.closeDelete()
                    this.$toast.success('Excluído com sucesso !')
                })
                .catch()
                .finally()
        },

        closeDelete() {
            this.dialogDelete = false
            this.editedIndex = -1
        },
        formatPeriod(item) {
            if (item.date_start || item.date_end) {
                const date1 = item.date_start == null ? '' : this.formatDate(item.date_start)
                const date2 = item.date_end == null ? '' : this.formatDate(item.date_end)
                return date1 + ' - ' + date2
            }
        },
        formatMoney(value) {
            value = parseFloat(value)
            if (value != null) {
                return value.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            }
        },
        formatDate(date) {
            if (date) {
                const result = new Date(date);
                return result.toLocaleDateString('pt-BR', {
                    timeZone: 'UTC'
                })
            }

        }
    },
}
</script>

<style scoped>
.card-tab {
    min-height: 420px;
    min-width: 736px;
}

.tool_table_fam {
    height: 50px;
}
</style>
