<template>
<v-dialog v-model="dialog" width="600">
    <v-card>
        <BarTitleDialog :title="title" @close="close" />

        <v-card-text>
            <v-container>
                <v-row no-gutters>
                    <v-col cols="8">
                        <v-text-field  v-model="editedItem.description" outlined label="Descrição" :hide-details="true"></v-text-field>
                    </v-col>
                    <v-col>
                        <vuetify-money v-model="editedItem.value" class="" class="ml-2" outlined label="Tarifa" />
                    </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col class="mr-2">
                      <InputDate v-model="editedItem.date_start" :dense="false" label="Data Inicial" ></InputDate>
                  </v-col>
                  <v-col>
                      <InputDate v-model="editedItem.date_end" :dense="false" label="Data Final"></InputDate>
                  </v-col>
                </v-row>

            </v-container>

        </v-card-text>
        <v-card-actions>
            <v-row>
                <v-col class="text-right">
                    <v-btn color="blue darken-1" x-large class="mr-4" text @click="close">
                        Fechar
                    </v-btn>
                    <v-btn :loading="loading" x-large color="primary" @click="save">
                        Salvar
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-actions>

    </v-card>
</v-dialog>
</template>

<script>
import {
    mdiClose,
    mdiDeleteOutline,
    mdiPencilOutline,
    mdiPlus
} from '@mdi/js'

import {
    mapActions,
    mapGetters,
    mapMutations
} from 'vuex'


import BarTitleDialog from '@/components/BarTitleDialog.vue'
import InputDate from '@/components/InputDate.vue'


export default {

    components: {
        BarTitleDialog,
        InputDate
    },
    props: {
        value: {
            type: Boolean
        },
        in_data: {
            type: Object
        }, //recebe os dados do hospede
        title: {
            type: String
        }

    },
    data: () => ({
        dialogDelete: false,
        dialog: false,
        loading: false,
        country_items: [],
        state_items: [],
        city_items: [],
        headers: [{
                text: 'ID',
                align: 'start',
                value: 'id',
            },
            {
                text: 'PERFIL',
                value: 'name'
            },
            {
                text: '',
                align: 'end',
                value: 'actions',
                sortable: false,
            },
        ],
        familiars: [],
        editedItem: {},
        defaultItem: {
            'description': '',
            'value':'',
        },

        options: {},
        tab: null,
        icons: {
            mdiPencilOutline,
            mdiDeleteOutline,
            mdiPlus,
            mdiClose,
        },

    }),

    watch: {
        value(val) {
            this.dialog = val
            if (val) {
                this.inicialize()
            }
        },
        dialog(val) {
            this.$emit('input', val)
            if (!val) {
                this.close();
            }
        },
        in_data(val) {
            this.editedItem = {
                ...val
            }

            this.setSubTariffs(this.editedItem.subtariff)

        }

    },

    created() {

    },

    computed: {
        ...mapGetters('tariff', ['getSubTariffs']),
    },

    methods: {
        ...mapMutations('tariff', ['setSubTariffs']),
        ...mapActions('tariff', ['store', 'update']),

        inicialize() {

        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = {
                    ...this.defaultItem
                }

            })

        },
        save() {
            this.loading = true
            this.editedItem.subtariff = this.getSubTariffs
            if (this.editedItem.id) {
                this.update(this.editedItem)
                    .then(response => {
                        const guest = response.data
                        this.close()
                        this.$emit('out_data', guest)
                        this.$toast.success('Salvo com sucesso !')
                    })
                    .catch()
                    .finally(() => {
                        this.loading = false
                    })
            } else {

                this.store(this.editedItem)
                    .then(response => {
                        const guest = response.data
                        this.close()
                        this.$toast.success('Salvo com sucesso !')
                        this.$emit('out_data', guest)
                    })
                    .catch()
                    .finally(() => {
                        this.loading = false
                    })
            }
        },
    },
}
</script>

<style scoped>
.card-tab {
    min-height: 420px;
    min-width: 736px;
}

.tool_table_fam {
    height: 50px;
}
</style>
